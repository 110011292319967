<template>
  <div class="sidebar">
    <div class="sidebar-item sidebar-item-bottom">
      <div class="title flex">
        <div class="title-icon cover ic-range"></div>
        <div class="title-text">检测范围</div>
      </div>
      <div class="content">
        <div class="items flex" v-for="(item, index) in rangeData" :key="index">
          <div class="icon contain"></div>
          <div class="text">{{ item }}</div>
        </div>
      </div>
    </div>
    <div class="sidebar-item sidebar-item-bottom">
      <div class="title flex">
        <div class="title-icon cover ic-contact"></div>
        <div class="title-text">论文客服咨询</div>
      </div>
      <div
        class="contact-item flex justify-center"
        v-for="(item, index) in contactData"
        :key="index"
        :class="'contact-item-' + item.icon"
        @mousemove="helpShow=true"
      >
        <div class="item-icon cover" :class="'ic-' + item.icon"></div>
        <div class="item-text">{{ item.name }}</div>
        <div class="help-me" v-if="helpShow" @mouseleave="helpShow=false">
          <div class="me-image cover"></div>
          <div class="me-close cover" @click="helpShow=false"></div>
        </div>
      </div>
    </div>
    <div class="sidebar-item sidebar-item-bottom">
      <div class="title flex">
        <div class="title-icon cover ic-xin"></div>
        <div class="title-text">温馨提示</div>
      </div>
      <div class="xin-content">
        <h3>1.如何统计论文字符数</h3>
        <p>
          打开文档，点击左上角的 审阅 功能 然后点击左上角 字数统计，查看
          字符数（不计空格）。不满1千字符算1千字符，例如5001-5999
          都算6千字符；12001-12999 都算13千字符。
        </p>
        <h3>2.检测报告</h3>
        <p>
          PDF报告、整体报告、比对报告---多版本报告以及总相似比、引用率、重复率、自写率---多指标提示
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rangeData: [
        "中文科技期刊论文全文数据库",
        "中文主要报纸全文数据库",
        "中国专利特色数据库",
        "博士/硕士学位论文全文数据库",
        "中国主要会议论文特色数据库",
        "港澳台文献资源",
        "外文特色文献数据全库",
        "维普优先出版论文全文数据库",
        "互联网数据资源/互联网文档资源",
        "高校自建资源库",
        "图书资源",
        "古籍文献资源",
        "年鉴资源",
        "IPUB原创作品",
      ],
      contactData: [
        // { name: "网页客服", icon: "zx" },
        // { name: "QQ 客服", icon: "qq" },
        { name: "论文客服", icon: "wx" },
      ],
      helpShow:false
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.sidebar {
  width: 280px;
  flex-shrink: 0;
  margin-left: 20px;
}

.sidebar-item {
  padding: 22px 28px;
  background: #fff;
  &.sidebar-item-bottom {
    margin-bottom: 0;
  }
  .title {
    height: 26px;
    line-height: 26px;
    padding-bottom: 22px;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      left: -28px;
      bottom: 0;
      height: 1px;
      width: 280px;
      background: #e6e6e6;
    }
    .title-icon {
      width: 26px;
      height: 26px;
      border-radius: 26px;
      &.ic-range {
        background-image: url("~@/assets/range.png");
      }
      &.ic-xin {
        background-image: url("~@/assets/xin.png");
      }
      &.ic-contact {
        background-color: #0c368a;
        background-image: url("~@/assets/contact.png");
      }
    }
    .title-text {
      padding-left: 15px;
      font-size: 18px;
    }
  }
  .content {
    width: 100%;
    height: 168px;
    margin-top: 22px;
    overflow-x: hidden;
    overflow-y: auto;
    .items {
      line-height: 24px;
      .icon {
        width: 20px;
        height: 24px;
        background-image: url("~@/assets/gou.png");
      }
      .text {
        padding-left: 15px;
      }
    }
  }
}
.contact-item {
  width: 100%;
  text-align: center;
  color: #6f6f6f;
  background: #f5f5f5;
  border: 1px solid #d6d6d6;
  font-size: 15px;
  line-height: 40px;
  margin-top: 20px;
  cursor: pointer;
  position: relative;
  .item-icon {
    width: 24px;
    height: 24px;
    margin-top: 8px;
    &.ic-qq {
      background-image: url("~@/assets/qq.png");
    }
    &.ic-wx {
      background-image: url("~@/assets/wx.png");
    }
    &.ic-zx {
      background-image: url("~@/assets/zx.png");
    }
  }
  .item-text {
    padding-left: 15px;
  }
}
.xin-content {
  h3 {
    line-height: 1.6;
    margin-bottom: 0;
    color: #0c368a;
    padding-top: 22px;
  }
  p {
    color: #757575;
    font-size: 12px;
    font-family: SimSun;
    line-height: 24px;
    margin: 0;
  }
}
.help-me {
  position: absolute;
  right: 240px;
  top: 50%;
  height: 420px;
  width: 320px;
  margin-top: -210px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.15);
  .me-image {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/me.png");
  }
  &::after {
    position: absolute;
    right: -10px;
    top: 50%;
    margin-top: 10px;
    height: 0;
    width: 0;
    border-bottom: 18px solid transparent;
    border-left: 18px solid #fff;
    border-right: 18px solid transparent;
    border-top: 18px solid transparent;
  }
}
.me-close {
  font-size: 15px;
  color: #979797;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  z-index: 9;
  background-image: url("~@/assets/close.png");
}
// .contact-item-wx:hover {
//   .help-me {
//     display: block;
//   }
// }
</style>
