<template>
  <div class="headers">
    <div class="wrapper flex justify-between">
      <div class="header-logo">
        <a href="/" class="logo cover"></a>
      </div>
      <div class="header-nav flex">
        <div
          class="nav-item"
          :class="isIndex == index ? 'item-active' : ''"
          v-for="(item, index) in navs"
          :key="index"
          @click="handleNav(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      navs: [
        { name: "网站首页", link: "Index" },
        { name: "维普查重", link: "Portal" },
        { name: "下载报告", link: "Report" },
        { name: "真伪查询", link: "" },
      ],
    };
  },
  methods: {
    handleNav(item) {
      if (item.link) {
        this.$router.push({
          name: item.link,
        });
      }else{
        window.open("https://vpcs.fanyu.com/verify","_blank")
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.headers {
  width: 100%;
  height: 76px;
  position: fixed;
  background-color: #fff;
  box-shadow: 2px 0 4px rgb(0 0 0 / 15%);
  text-align: center;
  line-height: 76px;
  left: 0;
  top: 0;
  z-index: 1000;
}
.header-logo {
  width: 280px;
  height: 40px;
  margin-top: 18px;
  .logo {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("~@/assets/logo.png");
  }
}
.header-nav {
  .nav-item {
    color: #333;
    cursor: pointer;
    margin-left: 40px;
    &:hover {
      color: #ff311c;
      font-weight: normal;
    }
    &.item-active {
      color: #ff311c;
      font-weight: 600;
    }
  }
}
.item-active:hover {
  font-weight: 600;
}
</style>
