
export const ACCESS_TOKEN = 'Access-Token'

function nowUrl() {
    let protocol = window.location.protocol,
        host = window.location.host;
    let url = `${protocol}//${host}`
    if (url) {
        if (url.charAt(url.length - 1) === "/") {
            url = url.substr(0, url.length - 1);
        }
    }
    return url;
}
//
export const BASE_URL =nowUrl() //"http://vp.lunwendaka.com"