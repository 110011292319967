import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import './yuuki'

Vue.config.productionTip = false

import { VueAxios } from './utils/request'
Vue.use(VueAxios)

router.afterEach((to, from, next) => {
  window.scrollTo({ // 页面切换时，滚动条平滑切换至顶部
    top: 0,
    behavior: 'smooth'
  })
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
