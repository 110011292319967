import axios from 'axios'
// import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { BASE_URL } from '@/store/mutation-types'

// 创建 axios 实例
const request = axios.create({
  baseURL: BASE_URL + "/t2/",
  timeout: 100000,
  headers: { 'Content-Type': 'application/json' }
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data

    if (error.response.status !== 200) {
      let status = error.response.status;
      let infos = {
        401: 'Unauthorized',
        403: 'Forbidden',
        404: 'Not Found',
        503: "Not Api",
        413: "文件太大,超过限制"
      }
      let messages = "Error"
      if (infos[status]) {
        messages = infos[status] + data.info
      }

      // notification.error({
      //   message: messages,
      //   description: `${status}: ` + data.info
      // })
      layer.closeAll();
      layer.alert(`${status}: ` + data.info)
    }


    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      // notification.error({
      //   message: 'Unauthorized',
      //   description: 'Authorization verification failed'
      // })
      layer.alert("Authorization verification failed")

    }
  }


  return Promise.reject(error)
}

//token失效,或错误提示
const errorStatus = (error) => {
  if (error.code != 200) {
    // notification.error({
    //   message: 'Error',
    //   description: error.info
    // })
    //layer.alert(error.info)
  }

  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {

  // if (config.method == "post" || config.method == "put") {
  //   config.headers['Content-Type'] = "application/json"
  // } else {
  //   config.params = config.data;
  //   config.headers['Content-Type'] ='multipart/form-data'
  // }
  if (!config.headers) {
    config.headers['Content-Type'] = "application/json"
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  let result = response.data;
  if (result.code == 200) {

    return result;
  } else {
    return errorStatus(result);
  }

}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
