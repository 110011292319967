import Vue from 'vue'
import "./main.scss"

import PageWrapper from "@/components/PageWrapper/index.vue"
Vue.component('page-wrapper', PageWrapper)

import PageHeader from "@/components/PageHeader/index.vue"
Vue.component('page-header', PageHeader)

import PageFooter from "@/components/PageFooter/index.vue"
Vue.component('page-footer', PageFooter)

import PageSide from "@/components/PageSide/index.vue"
Vue.component('page-side', PageSide)

