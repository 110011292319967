<template>
  <div class="yuuki-layout">
    <div class="yuuki-header flex justify-between">
      <div class="header-logo"></div>
      <div class="header-text flex">
        <div
          class="header-item"
          v-for="(item, index) in menus"
          :key="index"
          @click="handleMenu(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="yuuki-content flex">
      <div class="yuuki-aside">
        <div class="aside-title">系统检测文献库范围</div>
        <div
          class="aside-item"
          v-for="(item, num) in asides"
          :key="`key${num}`"
        >
          {{ item.name }}
        </div>

        <div v-if="contact">
          <div class="aside-title">联系客服</div>
          <div v-for="(item, index) in contacts" :key="`con${index}`">
            <div class="aside-item" v-if="item.value">
              <span v-if="item.name">{{ item.name }}{{ item.value }}</span>
              <span v-if="!item.name">
                <a
                  href="https://shop312029520.taobao.com/?spm=a217wi.openworkbeanchtmall.sellercard.15.17c95e16Qw6BxR"
                  target="_blank"
                  ><img
                    alt="点击这里给我发消息"
                    border="0"
                    src="//amos.im.alisoft.com/online.aw?v=2&amp;uid=誉英教育服务专营店&amp;site=cntaobao&amp;s=1&amp;charset=utf-8"
                  />&nbsp;</a
                >
              </span>
            </div>
          </div>
        </div>
        <!-- <div class="aside-title">系统主要适用于</div>
        <div class="aside-item" v-for="(item, index) in sys" :key="index">
          {{ item.name }}
        </div> -->
        <div class="aside-bottom"></div>
      </div>
      <div class="yuuki-container">
        <slot name="wrapper"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
const menus = [
  // { name: "论文降重", link: "Help", type: 0 },
  { name: "报告验真", link: "http://vpcs.cqvip.com/ReportTruth.aspx", type: 1 },
  { name: "提交检测", link: "Home", type: 0 },
];
const asides = [
  { name: "中文科技期刊论文全文数据库" },
  { name: "中文主要报纸全文数据库" },
  { name: "中国专利特色数据库" },
  { name: "博士/硕士学位论文全文数据库" },
  { name: "中国主要会议论文特色数据库" },
  { name: "维普优先出版论文全文数据库" },
  { name: "外文特色文献数据全库" },
  { name: "港澳台文献资源" },
  { name: "互联网数据资源" },
  { name: "联网文档资源" },
  { name: "高校自建资源库" },
  { name: "古籍文献资源" },
  { name: "IPUB原创作品" },
  { name: "年鉴资源" },
  { name: "图书资源" },
];

const contacts = [
  { name: "" },
  { name: "QQ:", value: "" },
  { name: "电话:", value: "" },
  { name: "微信:", value: "lunwendaka6" },
  { name: "邮箱:", value: "" },
];
const sys = [{ name: "专科、本科毕业论文" }];
function byURLParam(url, props) {
  let u = (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
    (a, v) => (
      (a[v.slice(0, v.indexOf("="))] = v.slice(v.indexOf("=") + 1)), a
    ),
    {}
  );
  return props ? u[props] : u;
}
export default {
  data() {
    return {
      menus,
      asides,
      contacts,
      sys,
      contact: false,
    };
  },
  methods: {
    handleHome() {
      this.$router.push({
        name: "Home",
      });
    },
    handleMenu(item) {
      if (item.type == 0) {
        let routeName = this.$route.name;
        if (routeName != item.link) {
          this.$router.push({
            name: item.link,
          });
        }
      } else {
        window.open(item.link, "_blank");
      }
    },
  },
  mounted() {
    let href = window.location.href;
    if (href) {
      let isUrl = byURLParam(href);
      this.contact=isUrl.contact?true:false
    }
  },
};
</script>

<style lang="scss" scoped>
.yuuki-layout {
  height: 100%;
}
.yuuki-header {
  position: relative;
  height: 84px;
  background: #fff;
  padding: 22px;
  .header-logo {
    width: 288px;
    height: 40px;
    background: url("../../assets/logo.png") no-repeat center;
    background-size: cover;
  }
  .header-item {
    height: 34px;
    line-height: 34px;
    font-size: 16px;
    padding-left: 30px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.yuuki-content {
  position: absolute;
  top: 84px;
  bottom: 0;
  left: 0;
  right: 0;
}
.yuuki-aside {
  width: 256px;
  height: 100%;
  flex-shrink: 0;
  overflow: auto;
}
.aside-bottom {
  height: 50px;
}
.yuuki-container {
  width: 100%;
  height: 100%;
  position: relative;
  border-left: 2px solid #109e0a;
}
.aside-title {
  padding: 20px 10px 0 12px;
  height: 38px;
  line-height: 38px;
  border-bottom: 1px dashed #cdcdcd;
  font-size: 17px;
  margin-bottom: 10px;
  font-weight: 600;
}
.aside-item {
  position: relative;
  padding: 0 10px 0 25px;
  height: 22px;
  line-height: 22px;
  &::before {
    position: absolute;
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: #000;
    top: 10px;
    left: 12px;
  }
}
.items {
  width: 100%;
  height: 200px;
}
</style>