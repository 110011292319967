import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    options: {
      title: ""
    },
    component: () => import('../views/New/Index.vue')
  },
  {
    path: '/portal',
    name: 'Portal',
    options: {
      title: ""
    },
    component: () => import('../views/New/Portal.vue')
  },
  {
    path: '/report',
    name: 'Report',
    options: {
      title: ""
    },
    component: () => import('../views/New/Report.vue')
  },
  {
    path: '/home',
    name: 'Home',
    options: {
      title: ""
    },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/down',
    name: 'Down',
    options: {
      title: ""
    },
    component: () => import('../views/Down.vue')
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import('../views/Help.vue')
  },

]

const router = new VueRouter({
  mode: 'history', //history
  // base: "/weipu/",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) { // 页面回退至上次访问的位置
      return savedPosition
    } else { // 页面刷新，滚动条置顶
      return {
        top: 0,
        behavior: 'smooth'
      }
    }
  }
})

export default router
