<template>
  <div class="footers">
    <div class="wrapper">
      <div class="footer-link flex justify-center">
        <div class="link-item" v-for="(item, index) in links" :key="index" @click="handleFooter(item)">
          {{ item.name }}
        </div>
      </div>
      <div class="footer-copy">
        Copyright © {{ years }} 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">皖ICP备2023013446号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      years: "",
      links: [
        { name: "首页", link: "Index" },
        { name: "维普报告下载", link: "Report" },
        { name: "真伪验证", link: "" },
        { name: "维普查重入口 ", link: "Portal" },
      ],
    };
  },
  mounted() {
    let date = new Date();
    this.years = date.getFullYear();
  },
  methods: {
    handleFooter(item) {
      if (item.link) {
        this.$router.push({
          name: item.link,
        });
      } else {
        window.open("https://vpcs.fanyu.com/verify", "_blank")
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footers {
  width: 100%;
  height: 150px;
  padding-top: 10px;
  background: #2d2e32;
  position: relative;

  .link-item {
    padding: 0 20px;
    height: 50px;
    line-height: 50px;
    color: #fff;
    cursor: pointer;

    &:hover {
      color: #d4d9df;
    }
  }

  .footer-copy {
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #999;

    span,a {
      color: #999;
    }
    a:hover{
      color: #fff;
    }
  }
}

@media screen and (min-width: 3000px) {
  .footers {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
